<template>
  <v-text-field
    :model-value="modelValue"
    append-inner-icon="mdi-magnify"
    :label="label"
    hide-details="auto"
    variant="outlined"
    density="compact"
    clearable
    data-cy="search"
    @update:model-value="$emit('update:modelValue', $event)"
    @keyup.enter="$emit('search')"
    @click:append-inner="$emit('search')"
    @click:clear="clear"
  />
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Suchen...',
    },
  },
  emits: ['update:modelValue', 'search'],
  methods: {
    clear() {
      this.$emit('update:modelValue', '')
      this.$emit('search')
    },
  },
}
</script>
