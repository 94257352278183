<template>
  <div>
    <v-container
      v-if="showFieldsInfo && headers.some((header) => header.hide)"
      class="pt-0"
    >
      <v-alert
        class="stepperalert pl-4 mb-4"
        border="start"
        border-color="secondary"
        closable
        density="compact"
      >
        <div class="text-body-2 font--text">
          Für eine bessere Übersicht haben wir einige Felder ausgeblendet.
        </div>
        <template #append>
          <v-btn
            color="primary"
            variant="text"
            @click="showFieldsMoreInfo = !showFieldsMoreInfo"
          >
            Mehr Informationen
          </v-btn>
        </template>

        <v-expand-transition>
          <div v-show="showFieldsMoreInfo">
            <v-divider class="mt-3 mb-4" />

            <v-row align="center">
              <v-col class="grow">
                <div class="text-body-2 font--text">
                  Sie können die zusätzlichen Felder einblenden und bearbeiten oder importieren.
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  variant="text"
                  @click="toggleFields"
                >
                  Felder anzeigen
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  variant="text"
                  @click="showFieldsInfo = false"
                >
                  Hinweis ausblenden
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-alert>
    </v-container>

    <v-container
      :fluid="showFields"
      :class="{ 'container-dense': dense && !showFields }"
      class="pt-0 wide"
    >
      <v-toolbar
        density="compact"
        color="transparent"
        class="pa-2"
      >
        <v-tooltip
          v-if="showFields"
          location="top"
        >
          <template #activator="{ props }">
            <span
              class="text-body-2"
              v-bind="props"
            >
              <span v-if="!updateOnly">
                {{ getAddedItems().length }} hinzugefügt | {{ deleted.length }} gelöscht |
              </span>
              {{ changed.length }} verändert
            </span>
          </template>
          <span>
            {{ items.length }} insgesamt | {{ currentItems.length }} angezeigt |
            {{ selected.length }} ausgewählt
          </span>
        </v-tooltip>

        <v-spacer />

        <app-search-field
          v-model="search"
          class="mx-2"
          rounded
        />

        <component
          :is="filterComponent"
          v-if="filterComponent"
          class="mx-2"
          :filter-params="additionalFilter"
          @apply-filter="$emit('apply-filter', $event)"
          @reset-filter="$emit('reset-filter', $event)"
          @change-filter="$emit('change-filter', $event)"
        ></component>
        <v-menu>
          <template #activator="{ props }">
            <v-btn
              class="mx-2"
              v-bind="props"
              color="primary"
              variant="elevated"
              rounded
            >
              <v-icon start> mdi-checkbox-multiple-outline </v-icon>
              Auswählen
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="!updateOnly"
              prepend-icon="mdi-plus-circle-outline"
              title="Hinzugefügte auswählen"
              @click="selectAdded()"
            >
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-pencil"
              title="Veränderte auswählen"
              @click="selectChanged()"
            >
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-filter-multiple"
              title="Angezeigte auswählen"
              @click="selectCurrent()"
            >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu :close-on-content-click="false">
          <template #activator="{ props }">
            <v-btn
              class="mx-2"
              v-bind="props"
              color="primary"
              variant="elevated"
              rounded
            >
              <v-icon start> mdi-order-bool-ascending-variant </v-icon>
              Anzeige
            </v-btn>
          </template>
          <v-list
            v-model:selected="filter"
            select-strategy="classic"
          >
            <v-list-item
              value="UNCHANGED"
              title="Unveränderte"
            >
              <template #prepend="{ isActive }">
                <v-checkbox-btn :model-value="isActive" />
              </template>
            </v-list-item>

            <v-list-item
              v-if="!updateOnly"
              value="ADDED"
              title="Hinzugefügte"
            >
              <template #prepend="{ isActive }">
                <v-checkbox-btn :model-value="isActive" />
              </template>
            </v-list-item>

            <v-list-item
              value="CHANGED"
              title="Veränderte"
            >
              <template #prepend="{ isActive }">
                <v-checkbox-btn :model-value="isActive" />
              </template>
            </v-list-item>

            <v-list-item
              v-if="!updateOnly"
              value="DELETED"
              title="Gelöschte"
            >
              <template #prepend="{ isActive }">
                <v-checkbox-btn :model-value="isActive" />
              </template>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-if="!updateOnly"
          class="mx-2"
          color="primary"
          variant="elevated"
          rounded
          @click="importDialog = true"
        >
          <v-icon start> mdi-file-import-outline </v-icon>
          Import
        </v-btn>

        <v-menu v-if="!updateOnly">
          <template #activator="{ props }">
            <v-btn
              class="ml-2"
              v-bind="props"
              color="primary"
              variant="elevated"
              rounded
            >
              <v-icon start> mdi-delete-outline </v-icon>
              Löschen
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="!selected.length"
              prepend-icon="mdi-select-remove"
              title="Ausgewählte löschen"
              @click="deleteSelected()"
            >
            </v-list-item>
            <v-list-item
              :disabled="!selected.length"
              prepend-icon="mdi-arrow-u-left-bottom"
              title="Ausgewählte nicht löschen"
              @click="undoDelete()"
            >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mt-6"
      >
        <v-data-table
          class="mt-4"
          :class="{ 'hide-footer': !updateOnly }"
          disable-pagination
          :hide-default-footer="!updateOnly"
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :search="search"
          :items-per-page="updateOnly ? 25 : -1"
          @current-items="(items) => (currentItems = items)"
        >
          <template #headers="{ columns: tableHeaders }">
            <tr>
              <td
                width="26"
                class="px-2"
              />
              <td
                width="64"
                class="pa-0"
              >
                <v-checkbox-btn
                  color="primary"
                  :model-value="items.length && selected.length === items.length"
                  :indeterminate="selected.length !== items.length && !!selected.length"
                  @click="selectAll"
                />
              </td>
              <template
                v-for="(header, key) in tableHeaders.filter((header) => showFields || !header.hide)"
                :key="key"
              >
                <td :width="header.width">
                  <v-menu
                    v-model="header.menu"
                    max-width="400"
                  >
                    <template #activator="{ props: menuProps }">
                      <div class="text-left">
                        <v-btn
                          class="text-body-2 font-weight-medium text-decoration-none text-left"
                          color="primary"
                          variant="text"
                          block
                          v-bind="menuProps"
                        >
                          <v-icon
                            v-if="sortBy === header.key"
                            size="small"
                            start
                          >
                            {{ sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                          </v-icon>
                          {{ header.title }}
                          <v-icon end> mdi-dots-vertical </v-icon>

                          <v-tooltip
                            v-if="header.hint"
                            location="top"
                            max-width="400"
                          >
                            <template #activator="{ props: tooltipProps }">
                              <v-icon
                                end
                                v-bind="tooltipProps"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <span>{{ header.hint }}</span>
                          </v-tooltip>
                        </v-btn>
                      </div>
                    </template>
                    <v-card>
                      <v-alert
                        v-if="header.hint"
                        class="stepperalert pl-4 ma-2"
                        border="start"
                        border-color="secondary"
                        density="compact"
                      >
                        {{ header.hint }}
                      </v-alert>
                      <v-list>
                        <v-list-item
                          v-if="header.sortable"
                          :prepend-icon="isSorted(header.key, false) ? 'mdi-close' : 'mdi-arrow-up'"
                          @click="setSort(header.key, false)"
                        >
                          <v-list-item-title>{{
                            isSorted(header.key, false)
                              ? 'Nicht mehr sortieren'
                              : 'Aufsteigend sortieren'
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="header.sortable"
                          :prepend-icon="
                            isSorted(header.key, true) ? 'mdi-close' : 'mdi-arrow-down'
                          "
                          @click="setSort(header.key, true)"
                        >
                          <v-list-item-title>{{
                            isSorted(header.key, true)
                              ? 'Nicht mehr sortieren'
                              : 'Absteigend sortieren'
                          }}</v-list-item-title>
                        </v-list-item>

                        <v-menu
                          v-if="!header.readOnly"
                          v-model="header.editSelectedMenu"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ props: menuProps }">
                            <v-list-item
                              :disabled="!selected.length"
                              v-bind="menuProps"
                              prepend-icon="mdi-pencil-box-multiple-outline"
                            >
                              <v-list-item-title>Ausgewählte bearbeiten</v-list-item-title>
                            </v-list-item>
                          </template>

                          <v-card>
                            <v-card-text>
                              <component
                                :is="getComponent(header)"
                                v-bind="header.inputProps"
                                v-model="header.editValue"
                                :rules="[]"
                                autofocus
                                hide-details="auto"
                                outlined
                                dense
                                :label="header.title"
                              />
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                variant="text"
                                @click="header.editSelectedMenu = false"
                              >
                                Abbrechen
                              </v-btn>
                              <v-btn
                                color="primary"
                                variant="text"
                                @click="setValue(header, false)"
                              >
                                Speichern
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>

                        <v-menu
                          v-if="!header.readOnly"
                          v-model="header.editAllMenu"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ props: menuProps }">
                            <v-list-item
                              v-bind="menuProps"
                              prepend-icon="mdi-pencil"
                            >
                              <v-list-item-title>Alle bearbeiten</v-list-item-title>
                            </v-list-item>
                          </template>

                          <v-card>
                            <v-card-text>
                              <component
                                :is="getComponent(header)"
                                v-bind="header.inputProps"
                                v-model="header.editValue"
                                :rules="[]"
                                autofocus
                                hide-details="auto"
                                outlined
                                dense
                                :label="header.title"
                              />
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                variant="text"
                                @click="header.editAllMenu = false"
                              >
                                Abbrechen
                              </v-btn>
                              <v-btn
                                color="primary"
                                variant="text"
                                @click="setValue(header, true)"
                              >
                                Speichern
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </v-list>
                    </v-card>
                  </v-menu>
                </td>
              </template>
            </tr>
          </template>
          <template #body></template>
          <template #tbody="{ items: bodyItems, columns: bodyHeaders }">
            <template
              v-for="(item, idx) in bodyItems"
              :key="idx"
            >
              <tr :class="{ 'text-grey': isDeleted(item.id) }">
                <td class="px-2">
                  <v-tooltip
                    v-if="isDeleted(item.id)"
                    location="top"
                  >
                    <template #activator="{ props }">
                      <v-avatar
                        size="10"
                        color="error"
                        v-bind="props"
                      />
                    </template>
                    <span>Gelöscht</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="isAdded(item.id)"
                    location="top"
                  >
                    <template #activator="{ props }">
                      <v-avatar
                        size="10"
                        color="success"
                        v-bind="props"
                      />
                    </template>
                    <span>Hinzugefügt</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="isChanged(item.id)"
                    location="top"
                  >
                    <template #activator="{ props }">
                      <v-avatar
                        size="10"
                        color="primary"
                        v-bind="props"
                      />
                    </template>
                    <span>Verändert</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-checkbox-btn
                    v-if="item.id !== 'new'"
                    color="primary"
                    :model-value="isSelected(item.id)"
                    @click="select(item.id)"
                  />
                </td>
                <template
                  v-for="header in bodyHeaders.filter((header) => showFields || !header.hide)"
                  :key="header.key"
                >
                  <td
                    :width="header.width"
                    style="align: center"
                  >
                    <app-bulk-edit-input
                      :item="item"
                      :header="header"
                      :cell-status="getCellStatus(header.key, item.id)"
                      @update:model-value="change(item, header, $event)"
                      @change="change(item, header, $event)"
                    />
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </v-data-table>
      </v-form>
      <v-dialog
        v-model="importDialog"
        max-width="1000"
      >
        <v-card>
          <v-card-title class="text-h7"> Daten aus Datei importieren </v-card-title>

          <v-card-text>
            <v-alert
              class="my-4 pl-4"
              border="start"
              border-color="secondary"
              closable
              density="compact"
            >
              <div class="text-body-2 font--text">
                Bitte wählen Sie ein Tabellendokument in einem gängigen Dateiformat (Microsoft Excel
                - xlsx / OpenOffice - ods) aus.<br />
                Es werden alle Zeilen des ersten Arbeitsblattes importiert. Die erste Zeile wird zur
                Zuordnung der Spalten verwendet.
              </div>
            </v-alert>
            <v-file-input
              v-model="file"
              label="Datei auswählen..."
              :loading="importLoading"
            />
            <v-table v-if="fileTable && fileTable.length && fileTable[0].length">
              <template #default>
                <thead>
                  <tr>
                    <td class="text-left">Zu importierende Spalten</td>
                    <td class="text-left">Spalten aus Datei</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="header in headers"
                    :key="header.key"
                  >
                    <td>{{ header.title }}</td>
                    <td>
                      <v-select
                        v-model="header.selectedColumn"
                        hide-details="auto"
                        :items="fileTable[0]"
                        clearable
                      />
                    </td>
                  </tr>
                  <tr />
                </tbody>
              </template>
            </v-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="error-darken-1"
              variant="text"
              @click="importDialog = false"
            >
              Abbrechen
            </v-btn>

            <v-btn
              color="success-darken-1"
              variant="text"
              :disabled="!(fileTable && fileTable.length && fileTable[0].length)"
              @click="importFile"
            >
              Importieren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { read, utils, set_cptable } from 'xlsx'
import * as cptable from 'xlsx/dist/cpexcel.full.mjs'
set_cptable(cptable)

export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    updateOnly: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    defaultItem: {
      type: Object,
      default: () => {
        return {}
      },
    },
    cellStatus: {
      type: Object,
      default: () => {
        return {}
      },
    },
    filterComponent: {
      type: Object,
      default: null,
    },
    additionalFilter: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:items', 'change-filter', 'reset-filter', 'apply-filter'],
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      selected: [],
      changed: [],
      deleted: [],
      currentItems: [],
      search: '',
      filter: ['UNCHANGED', 'ADDED', 'CHANGED'],
      importDialog: false,
      importLoading: false,
      file: null,
      fileTable: null,
      showFieldsInfo: true,
      showFieldsMoreInfo: false,
      showFields: false,
      valid: false,
    }
  },
  computed: {
    filteredItems() {
      const items = this.items
      // sort manually to show new item alway at bottom
      if (this.sortBy) {
        items.sort((a, b) => {
          // nulls sort after anything else
          if (a.id === 'new') {
            return 1
          }
          if (b.id === 'new') {
            return -1
          }
          if (a[this.sortBy] === b[this.sortBy]) {
            return 0
          }
          if (!a[this.sortBy]) {
            return this.sortDesc ? -1 : 1
          }
          if (!b[this.sortBy]) {
            return this.sortDesc ? 1 : -1
          }
          if (a[this.sortBy] < b[this.sortBy]) {
            return this.sortDesc ? 1 : -1
          }
          if (a[this.sortBy] > b[this.sortBy]) {
            return this.sortDesc ? -1 : 1
          }
        })
      }
      if (!this.updateOnly && !items.some((item) => item.id === 'new')) {
        items.push({
          ...this.defaultItem,
          id: 'new',
        })
      }
      return items.filter((item) => {
        if (item.id === 'new') {
          return true
        } else if (this.isDeleted(item.id)) {
          return this.filter.includes('DELETED')
        } else if (this.isAdded(item.id)) {
          return this.filter.includes('ADDED')
        } else if (this.isChanged(item.id)) {
          return this.filter.includes('CHANGED')
        } else {
          return this.filter.includes('UNCHANGED')
        }
      })
    },
  },
  watch: {
    file: {
      handler() {
        if (this.file) {
          this.importLoading = true
          const reader = new FileReader()
          reader.onload = (e) => {
            let data = e.target.result
            data = new Uint8Array(data)
            const workbook = read(data, {
              type: 'array',
            })

            /* DO SOMETHING WITH workbook HERE */
            const firstSheetName = workbook.SheetNames[0]
            /* Get worksheet */
            const worksheet = workbook.Sheets[firstSheetName]
            // It will prints with header and contents ex) Name, Home...
            const json = utils.sheet_to_json(worksheet, {
              header: 1,
            })
            this.fileTable = json
            this.importLoading = false
          }
          reader.readAsArrayBuffer(this.file)
        }
      },
    },
    fileTable: {
      handler() {
        // try to automatically assign columns
        this.importLoading = true
        if (this.fileTable && this.fileTable.length && this.fileTable[0].length) {
          this.headers.forEach((header) => {
            const columnNames = [header.title.toLowerCase()]
            if (header.synonyms) {
              columnNames.push(...header.synonyms)
            }
            this.fileTable[0].forEach((column) => {
              if (columnNames.includes(column.toLowerCase())) {
                header.selectedColumn = column
              }
            })
          })
        }
        this.importLoading = false
      },
    },
  },
  mounted() {},
  methods: {
    getComponent(header) {
      if (header.inputComponent) {
        return header.inputComponent
      } else if (header.action) {
        return markRaw(resolveComponent('v-checkbox-btn'))
      } else {
        return markRaw(resolveComponent('v-text-field'))
      }
    },
    setSort(field, desc) {
      if (this.isSorted(field, desc)) {
        this.sortBy = null
      } else {
        this.sortBy = field
        this.sortDesc = desc
      }
    },
    getItems() {
      return this.items.filter((item) => item.id !== 'new')
    },
    isSorted(field, desc) {
      return this.sortBy === field && this.sortDesc === desc
    },
    selectAll() {
      if (this.selected.length) {
        this.selected = []
      } else {
        this.selected = this.getItems().map((item) => item.id)
      }
    },
    select(itemId) {
      if (this.selected.includes(itemId)) {
        this.selected = this.selected.filter((selected) => selected !== itemId)
      } else {
        this.selected.push(itemId)
      }
    },
    change(item, header, value) {
      // dont change on unused change event
      if (value == null || typeof value !== 'object' || !value.srcElement) {
        this.setValueByPath(item, header.key, value)
        if (item.id === 'new') {
          item.id = 'new-' + Date.now()
          const items = this.items
          this.$emit('update:items', items)
          this.$nextTick(() => {
            if (
              this.$refs['input-' + item.id + '-' + header.key] &&
              this.$refs['input-' + item.id + '-' + header.key].length &&
              this.$refs['input-' + item.id + '-' + header.key][0].focus
            ) {
              this.$refs['input-' + item.id + '-' + header.key][0].focus()
            }
          })
        } else if (!this.isChanged(item.id) && !this.isAdded(item.id) && !header.action) {
          this.changed.push(item.id)
        }
      }
    },
    isSelected(itemId) {
      return this.selected.includes(itemId)
    },
    isChanged(itemId) {
      return this.changed.includes(itemId)
    },
    isAdded(itemId) {
      return itemId.startsWith('new-')
    },
    isDeleted(itemId) {
      return this.deleted.includes(itemId)
    },
    setValue(header, all) {
      let items = this.getItems()
      if (!all) {
        items = items.filter((item) => this.selected.includes(item.id))
      }
      items.forEach((item) => {
        this.change(item, header, header.editValue)
      })
      header.editAllMenu = false
      header.menu = false
    },
    selectAdded() {
      this.selected = this.getAddedItems().map((item) => item.id)
    },
    selectChanged() {
      this.selected = this.changed
    },
    selectCurrent() {
      this.selected = this.currentItems.map((item) => item.id)
    },
    deleteSelected() {
      this.deleted.push(...this.selected.filter((itemId) => !this.isDeleted(itemId)))
      this.selected = []
    },
    undoDelete() {
      this.deleted = this.deleted.filter((itemId) => !this.isSelected(itemId))
    },
    importFile() {
      this.importLoading = true
      const importData = this.fileTable.slice(1)
      this.headers.forEach((header) => {
        header.columnIndex = this.fileTable[0].indexOf(header.selectedColumn)
      })
      const objects = importData.map((data, i) => {
        const object = { id: 'new-' + (Date.now() + i * 10) }
        this.headers.forEach((header) => {
          if (header.columnIndex >= 0) {
            this.setValueByPath(object, header.key, data[header.columnIndex])
          }
        })
        return object
      })

      const items = this.getItems()
      items.push(...objects)
      items.push({
        ...this.defaultItem,
        id: 'new',
      })
      this.$emit('update:items', items)

      this.importDialog = false
      this.file = null
      this.fileTable = null

      this.importLoading = false
    },
    validate() {
      return this.$refs.form.validate()
    },
    getDeletedItems() {
      return this.getItems().filter((item) => this.isDeleted(item.id) && !this.isAdded(item.id))
    },
    getAddedItems() {
      return this.getItems().filter((item) => this.isAdded(item.id) && !this.isDeleted(item.id))
    },
    getChangedItems() {
      return this.getItems().filter((item) => this.isChanged(item.id) && !this.isDeleted(item.id))
    },
    toggleFields() {
      this.showFields = true
      this.showFieldsInfo = false
    },
    getCellStatus(headerKey, itemId) {
      return this.cellStatus && this.cellStatus[headerKey] && this.cellStatus[headerKey][itemId]
        ? this.cellStatus[headerKey][itemId]
        : null
    },
  },
}
</script>
<style>
.hide-footer {
  .v-data-table-footer {
    display: none;
  }
}
.v-container:not(.v-container--fluid).wide {
  max-width: 1800px !important;
}
.fake-field {
  padding: 9px 8px 11px 16px;
  margin: 4px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.fake-field-readonly {
  padding: 9px 8px 11px 16px;
  margin: 4px;
  border: none;
}
</style>
