<template>
  <div
    v-if="!editMode && header.displayType === 'text'"
    class="field-container"
    :style="{ 'max-width': header.width + 'px' }"
    @click="editMode = true"
  >
    <div
      class="fake-field"
      :class="{ 'outlined-div': !header.readOnly }"
    >
      <span class="truncate-text">
        {{ header.getText ? header.getText(item) : getValueByPath(item, header.key) }}
      </span>
    </div>
  </div>
  <template v-else>
    <v-progress-circular
      v-if="cellStatus === 'loading'"
      class="mx-2"
      size="20"
      width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-icon
      v-else-if="cellStatus === 'success'"
      color="success"
      class="mx-2"
      >mdi-check-bold</v-icon
    >
    <component
      :is="getComponent(header)"
      v-else
      :ref="'input-' + item.id + '-' + header.key"
      v-bind="{
        ...header.inputProps,
        ...(header.injectInputProps ? header.injectInputProps(item) : {}),
      }"
      :model-value="getValueByPath(item, header.key)"
      hide-details
      class="ma-1"
      color="primary"
      :bg-color="header.readOnly ? 'transparent' : 'white'"
      :rules="item.id !== 'new' ? header.rules : []"
      :readonly="header.readOnly"
      :variant="header.readOnly ? null : 'outlined'"
      :disabled="disabled"
      :placeholder="placeholder"
      density="compact"
      @update:model-value="$emit('update:modelValue', $event)"
      @change="$emit('change', $event)"
    />
  </template>
</template>

<script>
export default {
  props: {
    header: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    cellStatus: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      editMode: false,
    }
  },
  computed: {
    disabled() {
      return this.header.disabled && this.header.disabled(this.item)
    },
    placeholder() {
      return this.header.placeholder && this.header.placeholder(this.item).toString()
    },
  },
  methods: {
    getComponent(header) {
      if (header.inputComponent) {
        return header.inputComponent
      } else if (header.action) {
        return markRaw(resolveComponent('v-checkbox-btn'))
      } else {
        return markRaw(resolveComponent('v-text-field'))
      }
    },
    getValueByKey(object, path) {
      const pathAdjustments = {
        'physician.0.target_speciality': 'physician_target_speciality',
        'physician.0.training_regulation': 'physician_training_regulation',
        facilities: 'facility_name',
        specialities: 'klinik_specialities',
      }
      path = pathAdjustments[path] || path
      const value = path.split('.').reduce((acc, part) => acc && acc[part], object)
      return value || '&nbsp;'
    },
  },
}
</script>
<style scoped>
.field-container {
  position: relative;
  cursor: text;
  text-align: left;
}

.edit-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
}

.field-container:hover .edit-icon {
  visibility: visible;
}
.fake-field {
  height: 46px;
  border: none;
  padding: 10px 15px;
  background-color: white;
  text-align: left;
}
.outlined-div {
  color: #9e9e9e;
  border-radius: 4px;
  border: 1px solid;
}
.truncate-text {
  color: #2f523e;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
