<template>
  <app-bulk-edit
    ref="bulkedit"
    :key="'bulkedit-' + key"
    v-model:items="items"
    :headers="headers"
    :loading="loading"
    :update-only="updateOnly"
    :dense="dense"
    :default-item="defaultItem"
    :cell-status="cellStatus"
    :additional-filter="additionalFilter"
    @apply-filter="applyAdditionalFilter"
    @reset-filter="resetAdditionalFilter"
    @change-filter="changedAdditionalFilter"
  />
</template>
<script>
export default {
  props: {
    collection: {
      type: String,
      required: true,
    },
    filter: {
      type: Object,
      default: null,
    },
    headers: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    transformNewItem: {
      type: Function,
      default: (item) => item,
    },
    onNewItems: {
      type: Function,
      default: (newItemIds) => {},
    },
    processFilter: {
      type: Function,
      default: (filter) => filter,
    },
    createOnly: {
      type: Boolean,
    },
    updateOnly: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    defaultItem: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      items: [],
      additionalFilter: null,
      cellStatus: {},
      loading: false,
      key: 0,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.key = this.key + 1
      if (!this.createOnly) {
        let filter = this.filter ? JSON.parse(JSON.stringify(this.filter)) : {}
        filter = this.processFilter(filter, this.additionalFilter)
        this.loading = true
        const requestConfig = {
          filter,
          fields: [
            'id',
            ...this.headers
              .filter((header) => !header.action)
              .map((header) =>
                header.fields
                  ? header.fields.map((field) => header.key + '.' + field)
                  : header.key.replace(/\.\d+/g, '')
              )
              .flat(),
            ...this.fields,
          ],
          limit: -1,
        }
        const getRequest = () => {
          switch (this.collection) {
            case 'directus_users':
              return this.$readUsers(requestConfig)
            default:
              return this.$readItems(this.collection, requestConfig)
          }
        }
        await this.$cms.request(getRequest()).then((response) => {
          this.items = response
          this.loading = false
        })
      }
    },
    getItemToSave(item, transform = false) {
      let updatedItem = {}
      this.headers
        .filter((header) => !header.action && !header.readOnly)
        .forEach((header) => {
          let newValue = this.getValueByPath(item, header.key)
          if (newValue && header.transformValue) {
            newValue = header.transformValue(newValue)
          }
          this.setValueByPath(updatedItem, header.key, newValue)
        })
      if (transform) {
        updatedItem = this.transformNewItem(updatedItem)
      }
      return updatedItem
    },
    setCellstatus(headerKey, itemId, value) {
      if (this.cellStatus[headerKey]) {
        this.cellStatus[headerKey][itemId] = value
      } else {
        this.cellStatus[headerKey] = { [itemId]: value }
      }
    },
    async submit() {
      const validation = await this.$refs.bulkedit.validate()
      if (validation.valid) {
        try {
          if (!this.updateOnly) {
            let addedItems = this.$refs.bulkedit.getAddedItems()
            if (addedItems.length) {
              addedItems = addedItems.map((item) => this.getItemToSave(item, true))
              const getRequest = () => {
                switch (this.collection) {
                  case 'directus_users':
                    return this.$createUsers(addedItems, { fields: ['id'] })
                  default:
                    return this.$createItems(this.collection, addedItems, { fields: ['id'] })
                }
              }
              const newItems = await this.$cms.request(getRequest())
              await this.onNewItems(newItems.map((item) => item.id))
            }
          }

          const changedItems = this.$refs.bulkedit.getChangedItems()
          if (changedItems.length) {
            const promises = changedItems.map((item) => {
              const itemToUpdate = this.getItemToSave(item)
              const getRequest = () => {
                switch (this.collection) {
                  case 'directus_users':
                    return this.$updateUser(item.id, itemToUpdate)
                  default:
                    return this.$updateItem(this.collection, item.id, itemToUpdate)
                }
              }
              return this.$cms.request(getRequest())
            })
            await Promise.all(promises)
          }

          if (!this.updateOnly) {
            const deletedItems = this.$refs.bulkedit.getDeletedItems()
            if (deletedItems.length) {
              const getRequest = () => {
                switch (this.collection) {
                  case 'directus_users':
                    return this.$deleteUsers(deletedItems.map((item) => item.id))
                  default:
                    return this.$deleteItems(
                      this.collection,
                      deletedItems.map((item) => item.id)
                    )
                }
              }
              await this.$cms.request(getRequest())
            }
          }

          const headersWithAction = this.headers.filter((header) => header.action)
          const actionPromises = []
          headersWithAction.forEach((header) => {
            const items = this.items.filter((item) => this.getValueByPath(item, header.key))
            items.forEach((item) => {
              const action = async (header, item) => {
                this.setCellstatus(header.key, item.id, 'loading')
                await header.action(item, item[header.key])
                this.setCellstatus(header.key, item.id, 'success')
              }
              actionPromises.push(action(header, item))
            })
          })
          await Promise.all(actionPromises)
          this.loadData()
          return true
        } catch (e) {
          if (e._data && e._data.errors && e._data.errors.length) {
            const errors = e._data.errors
            if (
              errors.some(
                (error) =>
                  error.extensions.code === 'RECORD_NOT_UNIQUE' &&
                  error.extensions.field === 'email'
              )
            ) {
              this.$notificationStore.set({
                title: 'E-Mail-Adresse bereits vorhanden',
                text: 'Ein Eintrag hat eine E-Mail-Adresse, welche bereits im System vorhanden ist. Bitte übrerprüfen Sie die Adressen und versuchen Sie es erneut.',
                type: 'error',
              })
              return
            }
          }
          this.$notificationStore.set({
            title: 'Speichern fehlgeschlagen',
            text: 'Beim speichern der Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
            type: 'error',
          })
          console.log(e)
        }
      } else {
        this.$notificationStore.set({
          title: 'Speichern fehlgeschlagen',
          text: 'Ihre Eingabe war fehlerhaft. Bitte überprüfen Sie die rot markierten Felder.',
          type: 'error',
        })
      }
    },
    applyAdditionalFilter(params) {
      this.additionalFilter = params
      this.loadData()
    },
    resetAdditionalFilter() {
      this.additionalFilter = null
      this.loadData()
    },
    changedAdditionalFilter(params) {
      this.additionalFilter = params
    },
  },
}
</script>
